var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.userRoleVal && _vm.userRole == 'Staff' ? _c('div', {
    staticClass: "row"
  }, [_vm.statusAccess == 0 ? _c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "alert alert-warning"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("user.not-approve")) + ".")]), _c('button', {
    staticClass: "btn btn-sm btn-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.approveUserAccess
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("user.approve-access")) + " ")])])])]) : _vm._e()]) : _vm._e(), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "form-group col-md-6"
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("name")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(_vm._s(_vm.user.name))])]), _c('div', {
    staticClass: "form-group col-md-6"
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("gender")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm.user.gender == 1 ? _c('span', [_vm._v(_vm._s(_vm.$t("male")))]) : _vm._e(), _vm.user.gender == 2 ? _c('span', [_vm._v(_vm._s(_vm.$t("female")))]) : _vm._e()])]), _c('div', {
    staticClass: "form-group col-md-6"
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("mykad")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(_vm._s(_vm.user.ic_number))])]), _c('div', {
    staticClass: "form-group col-md-6"
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("office-no")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.user.office_phone_number ? _vm.user.office_phone_number : "-") + " ")])]), _c('div', {
    staticClass: "form-group col-md-6"
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("mobile-no")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(_vm._s(_vm.user.mobile_number))])]), _c('div', {
    staticClass: "form-group col-md-6"
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("address")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(_vm._s(_vm.fullAddress))])])]), _c('div', {
    staticClass: "row"
  }, [_vm.organizationType == 'Awaris' ? _c('div', {
    staticClass: "form-group col-md-6"
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("bankname")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(_vm._s(_vm.user.bank_name))])]) : _vm._e(), _vm.organizationType == 'Awaris' ? _c('div', {
    staticClass: "form-group col-md-6"
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("bank-account")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(_vm._s(_vm.user.bank_account_number))])]) : _vm._e()]), _c('hr'), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "form-group col-md-6"
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("status")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm.user.status == 1 ? _c('span', {
    staticClass: "badge badge-success"
  }, [_vm._v(_vm._s(_vm.$t("active")))]) : _vm._e(), _vm.user.status != 1 ? _c('span', {
    staticClass: "badge badge-warning"
  }, [_vm._v(_vm._s(_vm.$t("inactive")))]) : _vm._e()])]), _c('div', {
    staticClass: "form-group col-md-6"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("email")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(_vm._s(_vm.user.email))])]), _c('div', {
    staticClass: "form-group col-md-6"
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("username")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(_vm._s(_vm.user.username))])]), _c('div', {
    staticClass: "form-group col-md-6"
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("participated-inheritance-course")) + " ")]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm._f("Attended_course")(_vm.user.is_attended_course)) + " ")])])]), _c('hr'), _vm.userRoleVal ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "form-group col-md-6"
  }, [_vm.organizationType == 'Awaris' ? _c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("position")))]) : _vm._e(), _vm.organizationType != 'Awaris' ? _c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("user-role")))]) : _vm._e(), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(_vm._s(_vm.user_accesses.role))])]), _c('div', {
    staticClass: "form-group col-md-6"
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("org.nav2")))]), _vm.user_accesses && _vm.user_accesses.accessible ? _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.user_accesses.accessible.branch_name) + " ")]) : _vm._e()]), _vm.organizationType != 'Awaris' ? _c('div', {
    staticClass: "form-group col-md-6"
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("staff-id")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.user.staff_id ? _vm.user.staff_id : "-") + " ")])]) : _vm._e(), _vm.user.status == 1 ? _c('div', {
    staticClass: "form-group col-md-6"
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("user.status")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm.user_accesses.status == 1 ? _c('span', {
    staticClass: "badge badge-success"
  }, [_vm._v(_vm._s(_vm.$t("active")))]) : _vm._e(), _vm.user_accesses.status != 1 ? _c('span', {
    staticClass: "badge badge-warning"
  }, [_vm._v(_vm._s(_vm.$t("inactive")))]) : _vm._e()])]) : _vm._e()]) : _vm._e(), _c('hr'), _c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.back
    }
  }, [_c('i', {
    staticClass: "mdi mdi-chevron-left"
  }), _vm._v(_vm._s(_vm.$t("back")) + " ")]), _c('div', [this.user_accesses.role == 'Agent' && _vm.currentUser.username == 'staff' ? _c('button', {
    staticClass: "btn btn-primary mr-1",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.resendEmail(_vm.userId);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("send-noti-email")) + " ")]) : _vm._e(), ['IT', 'Staff'].includes(this.userRole) ? _c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$refs.changePasswordModal.showModal();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("navbar.change-password")) + " ")]) : _vm._e(), _vm._v(" "), ['Staff', 'Super Admin', 'IT'].includes(_vm.userRole) ? _c('router-link', {
    staticClass: "btn btn-primary",
    attrs: {
      "to": {
        name: 'organization.user.edit',
        params: {
          organization_id: _vm.organizationId,
          user_id: _vm.userId
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("update")))]) : _vm._e()], 1)]), _c('ChangePasswordModal', {
    ref: "changePasswordModal"
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }